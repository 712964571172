/* global tw */
import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Redirect = styled(Link)`
  ${tw(
    'p-1 lg:block mx-4 lg:mx-1 lg:mt-4 lg:hover:text-white bg-transparent text-2xl lg:hover:bg-gray-800 lg:hover:cursor-pointer lg:rounded-full border-transparent'
  )};
`

const Label = styled('span')`
  ${tw('hidden lg:block text-xs')};
`

const NavigationItem = (props) => {
  const { slug, title, icon } = props
  const enablePartialRouteMatching = slug !== '/'

  return (
    <>
      <Redirect to={slug} title={title} partiallyActive={enablePartialRouteMatching} activeClassName="active">
        <FontAwesomeIcon icon={icon} />
      </Redirect>
      <Label>{title}</Label>
    </>
  )
}

NavigationItem.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

export default NavigationItem
