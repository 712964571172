/* global tw */
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Container = styled('a')`
  background: linear-gradient(to bottom, #ed8936 0%, #ed8936 100%);
  background-size: 2px 2px;
  transition: background-size 0.2s;
  ${tw('bg-left-bottom bg-repeat-x no-underline')};
  &:hover {
    background-size: 2px 16px;
  }
`

const Link = (props) => {
  const { url, title, children } = props

  return (
    <Container href={url} title={title} target="_blank" rel="noopener nofollow noreferrer">
      {children}
    </Container>
  )
}

Link.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.any,
  children: PropTypes.any,
}

Link.defaultProps = {
  title: '',
  children: null,
}

export default Link
