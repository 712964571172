/* global tw */
import React from 'react'
import styled from '@emotion/styled'

import NavigationItem from './navigation-item'

const Container = styled('nav')`
  ${tw('w-screen lg:w-16 text-center sm:self-center')};
  @media (min-width: 992px) {
    &:before {
      ${tw('block absolute h-auto border-gray-800')};
      top: 8%;
      right: auto;
      bottom: 8%;
      border-left: 1px dashed;
      content: '';
    }
  }

  .active {
    ${tw('border-gray-800')}
    border-top: 1px solid;
    @media (min-width: 992px) {
      ${tw('border-t-0')};
      border-right: 1px solid;
      border-left: 1px solid;
    }
  }
`

const Navigation = () => (
  <Container>
    <NavigationItem slug="/" title="Home" icon="home" />
    <NavigationItem slug="/blog" title="Blog" icon="user-edit" />
    <NavigationItem slug="/photos" title="Photos" icon="photo-film" />
    <NavigationItem slug="/contact" title="Contact" icon="at" />
  </Container>
)

export default Navigation
