/* global tw */
import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from '@emotion/styled'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Container = styled('footer')`
  ${tw(
    'fixed lg:absolute bottom-0 w-full xl:w-1/4 py-4 text-gray-700 text-xs font-thin text-center leading-normal self-center sm:z-0'
  )};
`

const SocialMediaContainer = styled('div')`
  svg {
    ${tw('mx-2 mb-2 text-lg')};
  }
`

const Heart = styled('span')`
  ${tw('inline-block text-red-500')};
  -webkit-animation: heartbeat 0.75s ease-in-out infinite alternate;
  animation: heartbeat 0.75s ease-in-out infinite alternate;
  @-webkit-keyframes heartbeat {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes heartbeat {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
`

const Footer = () => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            version
          }
        }
      }
    `}
    render={(data) => (
      <Container>
        <SocialMediaContainer>
          <a href="https://www.linkedin.com/in/marc-santos/" target="_blank" rel="noreferrer noopener">
            <FontAwesomeIcon icon={['fab', 'linkedin']} title="LinkedIn" />
          </a>
        </SocialMediaContainer>
        <div>Designed and developed by Marc Santos.</div>
        <div>
          This website is a work in progress made with{' '}
          <Heart>
            <FontAwesomeIcon icon="heart" />
          </Heart>
          .
        </div>
        <div>
          &copy; 2011 - 2023. All Rights Reserved. Hikari{' '}
          <Link to="/changelog#prompt" title="Changelog">
            v{data.site.siteMetadata.version}
          </Link>
          .
        </div>
      </Container>
    )}
  />
)

export default Footer
