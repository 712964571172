/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

require('./data/svg-with-js.css')

exports.onRouteUpdate = () => {
  document.querySelector('main').scrollTo(0, 0)
}
