/* eslint-disable react/display-name, react/prop-types */
import React from 'react'
import { Global, css } from '@emotion/core'

const color = '#2d3748'
const background = '#fff'

export default ({ children }) => (
  <>
    <Global
      styles={css`
        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }
        html {
          overflow-x: hidden;

          box-sizing: border-box;

          color: ${color};
          background-color: ${background};

          text-rendering: optimizeLegibility;
          -ms-overflow-style: scrollbar;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        html,
        body {
          width: 100%;
          height: 100%;
          padding: 0;
          margin: 0;

          font-family: Muli, sans-serif;
          line-height: 140%;
        }
        h1,
        h2,
        h3 {
          font-family: Ovo, serif;
        }
        h4,
        h5,
        h6 {
          margin-bottom: 0;
        }
        a {
          color: ${color};
        }
      `}
    />
    {children}
  </>
)
/* eslint-disable react/display-name, react/prop-types */
