/* global tw */
import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

const Container = styled('div')`
  ${tw('my-8 mx-auto')};
`

const FluidImage = styled(Img)`
  ${tw('w-24 h-24 rounded-full shadow-lg')};
`

const Avatar = () => (
  <StaticQuery
    query={graphql`
      {
        avatar: file(relativePath: { eq: "profile.png" }) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={(data) => (
      <Container>
        <Link to="/" aria-label="Return to Home page">
          <FluidImage fluid={data.avatar.childImageSharp.fluid} />
        </Link>
      </Container>
    )}
  />
)

export default Avatar
