/* global tw */
import React from 'react'
import styled from '@emotion/styled'

import Link from './link'

const Container = styled('div')`
  ${tw('px-4 font-light')};
`

const Name = styled('h1')`
  ${tw('block my-0 text-5xl leading-none')};
  &:after {
    font-size: 0.95rem;
    content: '.';
  }
`

const Lead = () => (
  <Container>
    Hi, my name is
    <Name>Marc Santos</Name>
    Co-founder and director of{' '}
    <Link url="https://devzilla.co.nz" title="Devzilla Limited">
      Devzilla Limited
    </Link>
    , and a Senior Application Engineer at Verizon Connect.
  </Container>
)

export default Lead
