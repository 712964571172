/* global tw */
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faHome,
  faUserEdit,
  faPhotoFilm,
  faAt,
  faExclamationTriangle,
  faHeart,
  faComments,
  faDesktop,
  faTrophy,
  faUniversity,
  faPlane,
  faShip,
  faTerminal,
  faKiwiBird,
  faBookOpen,
  faPiggyBank,
  faLaptopHouse,
  faFlag,
  faPassport,
  faClock,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import 'typeface-muli'
import 'typeface-ovo'
import 'typeface-source-code-pro'

import Global from './global'
import Sidebar from '../components/sidebar'
import Navigation from '../components/navigation'

library.add(
  faHome,
  faUserEdit,
  faPhotoFilm,
  faAt,
  faExclamationTriangle,
  faHeart,
  faComments,
  faDesktop,
  faTrophy,
  faUniversity,
  faPlane,
  faShip,
  faTerminal,
  faKiwiBird,
  faBookOpen,
  faPiggyBank,
  faLaptopHouse,
  faFlag,
  faPassport,
  faClock,
  faPaperPlane,
  fab
)

const Container = styled('div')`
  ${tw('flex flex-wrap max-w-screen-2xl m-auto shadow-lg')};
`

const Content = styled('div')`
  ${tw('z-10 flex flex-wrap lg:flex-nowrap lg:flex-row-reverse lg:flex-1 lg:h-screen w-full mb-32 lg:mb-0')};
`

const Main = styled('main')`
  ${tw('w-full lg:overflow-y-auto')};
  @media (min-width: 992px) {
    &:before {
      ${tw('block absolute h-auto border-gray-800')};
      top: 8%;
      right: auto;
      bottom: 8%;
      border-left: 1px dashed;
      content: '';
    }
  }
`

const Section = styled('section')`
  ${tw('h-full p-4 bg-white')};
`

const Secret = styled('div')`
  ${tw('fixed z-10 opacity-0')};
  bottom: 20px;
  right: 20px;
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            title
            description
            keywords
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
            { name: 'keywords', content: data.site.siteMetadata.keywords },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Global>
          <Container>
            <Sidebar />
            <Content>
              <Navigation />
              <Main>
                <Section>{children}</Section>
              </Main>
            </Content>
          </Container>
          <Secret />
        </Global>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
