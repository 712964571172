/* global tw */
import React from 'react'
import styled from '@emotion/styled'

import Lead from './lead'
import Footer from './footer'

import Avatar from './avatar'

const Container = styled('div')`
  ${tw('flex flex-col w-full lg:w-1/3 xl:w-1/4 px-2 mb-4')};
`

const Sidebar = () => (
  <Container>
    <Avatar />
    <Lead />
    <Footer />
  </Container>
)

export default Sidebar
